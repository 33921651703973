(function ($) {
  Drupal.behaviors.gnavLocatorV1 = {
    attach: function (context) {
      var $template = $('.js-gnav__locator--v1', context);

      $template.each(function (index, el) {
        // do
      });
    }
  };
})(jQuery);
